import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import "../style.scss"

const IndexPage = () => (
  <Layout>
    <div className="section">
      <div className="title is-4">
        規約とポリシー
      </div>
      <aside className="menu">
        <p className="menu-label">
          natadeCOCO 利用規約
        </p>
        <ul className="menu-list">
          <li><Link to="/terms/20191122">2019年11月22日 制定</Link></li>
        </ul>
        <p className="menu-label">
          natadeCOCO コンテンツ配信サービス 利用規約
        </p>
        <ul className="menu-list">
          <li><Link to="/terms/provider/20200901">2020年09月01日 改定</Link></li>
          <li><Link to="/terms/provider/20191224">2019年12月24日 制定</Link></li>
        </ul>
        <p className="menu-label">
          COCOPAY 利用規約
        </p>
        <ul className="menu-list">
          <li><Link to="/terms/pay/2021xxxx">2021年xx月xx日 制定</Link></li>
        </ul>
        <p className="menu-label">
          プライバシーポリシー
        </p>
        <ul className="menu-list">
          <li><Link to="/policies/privacy/20191219">2019年12月19日 制定</Link></li>
        </ul>
        <p className="menu-label">
          特定商取引に関する表記
        </p>
        <ul className="menu-list">
          <li><Link to="/notations/sctl/20200108">2020年01月08日 制定</Link></li>
        </ul>
      </aside>
    </div>
  </Layout>
)

export default IndexPage
